<template>
    <v-menu
        :bottom="bottom"
        :close-on-content-click="closeOnClick"
        dense
        :offset-x="left || right"
        :offset-y="bottom"
        :left="left"
        :right="right"
        :open-on-hover="openOnHover"
        :open-delay="openDelay"
        v-model="menuValue">
        <template v-slot:activator="{ on, attrs }">
            <slot name="activator" v-bind:on="on" v-bind:attrs="attrs" v-bind:openDialog="toggleOpen">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :block="block"
                    :class="buttonClass"
                    :height="buttonHeight"
                    :icon="icon != null && text == null"
                    :large="large"
                    :small="small"
                    :text="text != null && !isButton"
                    :title="title">
                    <v-icon
                        v-if="icon != null"
                        :small="small"
                        :large="large"
                        :left="text != null"
                        :class="isTrueFunc() ? 'success--text' : buttonClass">{{ icon }}</v-icon>
                    {{ text }}
                </v-btn>
            </slot>
        </template>

        <v-list
            v-if="asyncItem != null || navigation == null"
            :class="listClass"
            :min-width="minWidth"
            :max-width="maxWidth">
            <v-subheader v-if="!hideHeader" :class="listClass" dense>{{ title }}<v-spacer /><v-icon small>{{ icon }}</v-icon></v-subheader>
            <v-divider v-if="!hideHeader" :class="listClass" class="my-1" />
            <slot 
                v-bind:data="data"
                v-bind:item="asyncItem"
                v-bind:save="save" />
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'BT-Menu',
    data: function() {
        return {
            asyncItem: null,
            currentID: null,
            itemJSON: null,
            loadingMsg: false,
            menuValue: false,
            snapshotItemJSON: null
        }
    },
    props: {
        buttonClass: {
            type: String,
            default: null
        },
        buttonHeight: null,
        entityProp: {
            type: String,
            default: 'id'
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: false
        },
        bottom: {
            type: Boolean,
            default: false
        },
        closeOnClick: {
            type: Boolean,
            default: false
        },
        getOnOpenAsync: {
            type: Function,
            default: null
        },
        icon: {
            type: String,
            default: 'mdi-timer'
        },
        ignoreID: {
            type: Boolean,
            default: false
        },
        isButton: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        isTrue: {
            type: Function,
            default: null
        },
        item: {
            type: Object,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        left: {
            type: Boolean,
            default: false
        },
        listClass: {
            type: String,
            default: null //'bg-primary lighten-1 fg-primary--text'
        },
        maxWidth: {
            type: String,
            default: null
        },
        minWidth: {
            type: String,
            default: '350'
        },
        navigation: {
            type: String,
            default: null
        },
        offsetX: {
            type: Boolean,
            default: true
        },
        openDelay: {
            type: Number,
            default: 0
        },
        openOnHover: {
            type: Boolean,
            default: false
        },
        proxyID: {
            type: String,
            default: null
        },
        right: {
            type: Boolean,
            default: false
        },
        saveOnClose: {
            type: Boolean,
            default: false
        },
        single: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        trackChanges: {
            type: Boolean,
            default: true
        },
        trackIgnore: {
            type: Array,
            default: null
        },
    },
    computed: {
        data() {
            return {
                isEditing: this.isEditing || (this.navigation != null && this.$canEdit(this.navigation)),
            }
        },
        isChanged() {
            return this.itemJSON != this.snapshotItemJSON;
        },
    },
    mounted() {
        if (this.item != null && this.getOnOpenAsync == null) {
            this.asyncItem = this.item;
            this.snapshotItemJSON = this.createSnapshot(this.asyncItem);
            this.itemJSON = this.snapshotItemJSON;
        }
    },
    watch: {
        asyncItem: {
            deep: true,
            handler() {
                this.itemJSON = this.createSnapshot(this.asyncItem);
            }
        },
        itemValue: function(val) {
            if (val != this.currentID) {
                this.pullItem();
            }
        },
        menuValue: function(val, oldVal) {
            if (!val && oldVal) {
                this.$emit('close');
                if (this.saveOnClose) {
                    this.save();
                }
            }
            else if (val && !oldVal) {
                this.$emit('open');
                this.pullItem();
            }
        }
    },
    methods: {
        createSnapshot(data) {
            var copy = this.copyDeep(data);
            
            if (this.isLengthyArray(this.trackIgnore)) {
                this.trackIgnore.forEach(x => {
                    delete copy[x];
                });
            }

            return JSON.stringify(copy);
        },
        isTrueFunc() {
            if (this.isTrue == null) {
                return false;
            }
            if (this.asyncItem == null) {
                return false;
            }
            return this.isTrue(this.asyncItem);
        },
        toggleOpen() {
            this.menuValue = !this.menuValue;
        },
        async pullItem() {
            if (this.getOnOpenAsync != null) {
                this.loadingMsg = 'Pulling';
                try {
                    this.asyncItem = await this.getOnOpenAsync(this.item);
                }
                finally {
                    this.loadingMsg = null;
                }
            }
            else {
                if (this.navigation != null && !this.$canView(this.navigation)) {
                    return;
                }

                if (this.item != null) {
                    this.asyncItem = this.item;
                    return;
                }

                this.loadingMsg = 'Loading';
                this.$forceUpdate();
                this.currentID = this.itemValue;

                if (this.currentID == null && !this.ignoreID) {
                    this.asyncItem = null;
                    this.loadingMsg = null;
                }
                else {
                    var params = {
                        includeDetails: false
                    }

                    if (this.isLengthyArray(this.itemProperties)) {
                        params.properties = this.itemProperties.toString();
                    }

                    try {
                        if (this.single) {
                            this.asyncItem = await this.$BlitzIt.store.get(this.navigation, this.currentID, null, false, this.proxyID);
                        }
                        else {
                            var res = await this.$BlitzIt.store.getAll(this.navigation, params, false, this.proxyID);
                            this.asyncItem = this.ignoreID ? res : res.find(y => this.getNestedValue(y, this.entityProp) === this.currentID);
                        }

                        this.snapshotItemJSON = this.createSnapshot(this.asyncItem);
                        this.itemJSON = this.snapshotItemJSON;
                    }
                    catch (err) {
                        this.loadingMsg = this.extractErrorDescription(err);
                    }
                    finally {
                        this.loadingMsg = null;
                    }
                }
            }
        },
        async save() {
            if (this.navigation != null && !this.$canEdit(this.navigation)) {
                return;
            }

            if (this.trackChanges && !this.isChanged) {
                return;
            }

            this.loadingMsg = 'Saving';
            try {
                var res = await this.$BlitzIt.store.patch(this.navigation, this.asyncItem, this.proxyCompanyID);
                this.asyncItem.rowVersion = res.rowVersion;
            }
            catch (err) {
                this.loadingMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
    }
}
</script>